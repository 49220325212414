.App {
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modal {
  position: fixed;
  top: 25%;
  left: 25%;
  background-color: white;
  padding: 20px;
  border: 1px solid black;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-content {
  text-align: center;
  font-size: larger; /* Adjust the size as needed */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-bar {
  background-color: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar-title {
  color: white;
  margin-left: 10px;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.styled-select {
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.close-button {
  padding: 5px 15px;
  background-color: #616161;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center; /* Aligns the button at the center */
}

.close-button:hover {
  background-color: #404040;
}

.learn-more-button {
  padding: 5px 15px;
  background-color: #616161;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.learn-more-button:hover {
  background-color: #404040;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

